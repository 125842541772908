import React from 'react';
import classes from '../../styles/blocks/Recommended.module.css';

const Recommended = () => {
    return (
        <div>

        </div>
    );
};

export default Recommended;